<template>
    <Content :search="false">
        <template v-slot:beforeSearch>
            <div class="flex items-center text-lg font-semibold dark:text-gray-300">
                <h2>Pasūtījums</h2>
            </div>
        </template>

        <template v-slot:content>
            <div>
                <div class="sm:rounded-md mb-3">
                    <ul role="list" class="">
                        <template v-if="order">
                            <template v-if="order.status && order.status.id === 4 && order.customer && order.customer.notes">
                                <PopupAlertRelative :text="order.customer.notes"/>
                            </template>

                            <template v-if="!displayForm('createOrderInvoiceForm')">
                                <OrderDetails :item="order"/>
                            </template>

                            <template v-if="displayForm('createOrderInvoiceForm')">
                                <CreateOrderInvoice :order="order"/>
                            </template>

                            <template v-if="order.status.id === 1">
                                <div class="flex w-full">
                                    <button @click="updateStatus(2)" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white
                          bg-main4 hover:bg-main3 transition duration-150 ease-out hover:ease-in mb-3 ml-auto"
                                    >
                                        Apstiprināt pasūtījumu
                                    </button>
                                </div>
                            </template>

                            <template v-if="order.status.id === 2">
                                <div class="flex w-full">
                                    <button @click="updateStatus(1)" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white
                          bg-main4 hover:bg-main3 transition duration-150 ease-out hover:ease-in mb-3 ml-auto"
                                    >
                                        Atgriezt statusā Jauns
                                    </button>

                                    <template v-if="order.allow_complete">
                                        <button @click="updateStatus(3)" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white
                          bg-green-500 hover:bg-green-600 transition duration-150 ease-out hover:ease-in mb-3 ml-3"
                                        >
                                            Pabeigt pasūtījumu
                                        </button>
                                    </template>

                                </div>
                            </template>

                            <template v-if="order.status.id === 3 && !displayForm('createOrderInvoiceForm')">
                                <div class="flex w-full">
                                    <button @click="revertStatusToAccepted()" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white
                      bg-main4 hover:bg-main3 transition duration-150 ease-out hover:ease-in mb-3 ml-auto"
                                    >
                                        Atgriezt statusā Akceptēts
                                    </button>

                                    <template v-if="!order.invoice">
                                        <button @click="showForm('createOrderInvoiceForm')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white
                      bg-green-500 hover:bg-green-600 transition duration-150 ease-out hover:ease-in mb-3 ml-3"
                                        >
                                            Izrakstīt rēķinu
                                        </button>
                                    </template>

                                    <template v-if="order.invoice">
                                        <button @click="showForm('createCreditNoteForm')" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white
                      bg-red-500 hover:bg-red-600 transition duration-150 ease-out hover:ease-in mb-3 ml-3"
                                        >
                                            Izrakstīt Kredītrēķinu
                                        </button>
                                    </template>

                                </div>
                            </template>

                        </template>

                        <template v-if="order && order.customer && !displayForm('createCreditNoteForm')">
                            <AllOrderItems
                                :order="order"
                                :orderStatuses="orderStatuses"
                                :itemsWithLowStock="itemsWithLowStock"
                            />
                        </template>

                        <template v-if="this.displayForm('orderDeliveryForm') && this.$userCan('manage_orders')">
                            <OrderDeliveryForm :order="order" :status_id="orderStatuses.readyForDispatch"/>
                        </template>

                        <template v-if="this.displayForm('createCreditNoteForm') && this.$userCan('manage_orders')">
                            <CreateCreditNote :order="order"/>
                        </template>

                    </ul>
                </div>
            </div>
        </template>
    </Content>
</template>

<script>
import {mapGetters} from "vuex";
import {defineAsyncComponent} from 'vue'

const OrderDetails = defineAsyncComponent(() =>
    import('@/components/Orders/OrderDetails/OrderDetails'))
const AllOrderItems = defineAsyncComponent(() =>
    import('@/components/Orders/OrderItems/AllOrderItems'))
const PopupAlertRelative = defineAsyncComponent(() =>
    import('@/components/Components/PopupAlertRelative'))
const OrderDeliveryForm = defineAsyncComponent(() =>
    import('@/components/Orders/OrderDelivery/OrderDeliveryForm'))
const CreateOrderInvoice = defineAsyncComponent(() =>
    import('@/components/Orders/OrderInvoice/CreateOrderInvoice'))
const CreateCreditNote = defineAsyncComponent(() =>
    import('@/components/Orders/CreditNotes/CreateCreditNote'))

import OrderStatuses from "@/services/Helpers/OrderStatuses";

export default {
    name: "Order",
    components: {
        OrderDetails,
        AllOrderItems,
        PopupAlertRelative,
        OrderDeliveryForm,
        CreateOrderInvoice,
        CreateCreditNote
    },
    data: () => ({
        orderStatuses: null,
        itemsWithLowStock: [],
    }),
    created() {
        this.orderStatuses = OrderStatuses;
        this.$store.dispatch("clearSingleOrder");
        this.$store
            .dispatch("getSingleOrder", this.$route.params.orderId)
    },
    computed: {
        ...mapGetters({
            user: "user",
            order: "order",
            loading: "loading",
            formsForDisplay: "formsForDisplay",
        }),
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },
        showForm(formName) {
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName);
        },
        cancelOrder() {
            if (confirm("Vai esi drošs?")) {
                this.$store.dispatch("cancelOrder", this.order.id);
            }
        },

        updateOrder(payload) {
            this.$store.dispatch("updateOrder", {
                id: this.order.id,
                data: payload,
            })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.itemsWithLowStock = error.response.data.errors.status_id;
                    }
                });
        },
        revertStatusToAccepted() {
            this.$store.dispatch("revertStatusToAccepted", {
                order_id: this.order.id,
            })
        },

        updateStatus(status_id) {
            this.$store.dispatch("updateOrderStatus", {
                order_id: this.order.id,
                data: {
                    status_id: status_id,
                }
            })
        }
    },
};
</script>

<style>
</style>