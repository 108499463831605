class OrderStatuses {
    static get newOrder() {
        return 1;
    }
    static get inProcess() {
        return 2;
    }
    static get inFulfillment() {
        return 3;
    }
    static get goodsAvailable() {
        return 4;
    }
    static get readyForDispatch() {
        return 6;
    }
    static get dispatched() {
        return 7;
    }
    static get delivered() {
        return 8;
    }
    static get cancel() {
        return 9;
    }

    static forAcceptanceAct() {
        return [
            OrderStatuses.goodsAvailable,
            OrderStatuses.readyForDispatch,
            OrderStatuses.dispatched,
            OrderStatuses.delivered,
        ];
    }

    static statuses() {
        return [
            { id: 1, name: "Jauns" },
            { id: 2, name: "Apstrādē" },
            { id: 3, name: "Sagādē" },
            { id: 4, name: "Preces pieejamas" },
            { id: 5, name: "Preces nav pieejamas" },
            { id: 6, name: "Sagatavots nosūtīšanai" },
            { id: 7, name: "Nodots piegādei" },
            { id: 8, name: "Piegādāts" },
            { id: 9, name: "Atcelts" },
        ];
    }
}

export default OrderStatuses;
